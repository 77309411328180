
import { Component, Ref, Vue } from "vue-property-decorator";
import CourseForm from "@/components/CourseForm.vue";
import ICourse from "@/types/course";
import { createCourse, deleteCourse, editCourse, getCourse } from "@/api/api";
import BackButton from "@/components/BackButton.vue";

@Component({
  components: { BackButton, CourseForm },
})
export default class CreateCourse extends Vue {
  @Ref("form") private readonly form!: CourseForm;
  private isEditPage = false;

  private prefetchedCourse: ICourse = {
    coverImage: "",
    description: "",
    name: "",
    public: false,
    strictOrder: false,
  };

  private get areFilesLoading() {
    return this.$store.getters["fileLoadModule/areFilesLoading"];
  }

  private updatedCourse: ICourse = { ...this.prefetchedCourse };

  private loading = false;

  private get courseId(): string {
    return this.$route.params.id;
  }

  async mounted(): Promise<void> {
    const id = this.$route.params.id;
    this.isEditPage = !!id;

    if (!this.isEditPage) {
      return;
    }

    try {
      this.prefetchedCourse = await getCourse(id);
      this.updatedCourse = { ...this.updatedCourse };
    } catch (e) {
      console.error(e);
    }
  }

  private goToCourses() {
    this.$router.push("/courses");
  }

  private onChange(course: ICourse) {
    this.updatedCourse = course;
  }

  private async editCourse() {
    const isValid = this.validateForm();

    if (!isValid) {
      return;
    }

    try {
      await editCourse({ ...this.updatedCourse, courseID: +this.courseId });
      this.goToCourses();
    } catch (e) {
      console.error(e);
    }
  }

  private async deleteCourse() {
    if (!this.courseId) {
      return;
    }

    try {
      await deleteCourse(+this.courseId);
      this.goToCourses();
    } catch (e) {
      console.error(e);
    }
  }

  private validateForm(): boolean {
    return this.form.validate();
  }

  private async submit() {
    const isValid = this.validateForm();

    if (!isValid) {
      return;
    }

    try {
      await createCourse(this.updatedCourse);
      await this.$router.push("/courses");
    } catch (e) {
      console.error(e);
    }
  }

  private get title() {
    return `${this.isEditPage ? "Редактировать" : "Создать"} курс`;
  }

  private beforeDestroy() {
    this.$store.dispatch("fileLoadModule/removeAll");
  }
}


import { Component, Prop, Watch } from "vue-property-decorator";
import ICourse from "@/types/course";
import RequiredRuleMixin from "@/mixins/required-rule.mixin";
import { mixins } from "vue-class-component";
import ImageLoaderWithPreview from "@/components/ImageLoaderWithPreview.vue";

@Component({
  components: { ImageLoaderWithPreview },
})
export default class CourseForm extends mixins(RequiredRuleMixin) {
  @Prop() private readonly prefetchedCourse!: ICourse | null;
  @Prop() private readonly isEdit!: boolean;

  private course: ICourse = {
    coverImage: "",
    description: "",
    name: "",
    public: false,
    strictOrder: false,
  };

  @Watch("prefetchedCourse", { immediate: true })
  updateForm(newCourse: ICourse): void {
    this.course = { ...newCourse };
  }

  @Watch("course", { deep: true })
  onCourseChange(course: ICourse): void {
    this.$emit("change", course);
  }

  public validate(): boolean {
    return this.validateForm();
  }
}
